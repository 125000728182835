import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class accountConfigurationService {
  BASE_DOMAIN = environment.BASE_DOMAIN  

  constructor(private api : ApiService,private http: HttpClient) { }
  
  addAccount(formData:any){
    const url = `${environment.New_BASE_DOMAIN}account-service/config/createAccount`;
    return this.http.post<any>(url, formData);
  }

  getAccountList(formData:[], pageNumber:number, pageSize:number,sortingCol:any, direction:any){
      const url = (environment.New_BASE_DOMAIN + 'account-service/info/getAccounts?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&direction=' + direction)
      return this.api.post(url,formData);
  }
  updateAccount(formData:any){
    const url = `${environment.New_BASE_DOMAIN}account-service/config/updateAccount`;
    return this.http.put<any>(url, formData);
  }
  getServiceMaster(){
    const url = `${environment.New_BASE_DOMAIN}account-service/info/getServiceMaster`;
    return this.http.get<any>(url);
  }
  addOrUpdateTheme(formData:any){
    const url = (environment.New_BASE_DOMAIN + 'account-service/config/addOrUpdateTheme')
    return this.api.post(url,formData);
  }
  upload(file: File): Observable<HttpEvent<any>> {
    // 'ASN' 1 1 'ASN'
    var url :any
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      'VER': '1',
      'DEVICE-TYPE':'Web'
    });
    headers.append('Content-Type', '	application/json');
    url = `${environment.New_BASE_DOMAIN}utils-service/uploads/uploadFiles`
    
    const req = new HttpRequest('POST', url, formData, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }
}
