import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class roleService {
  BASE_DOMAIN = environment.BASE_DOMAIN
  private activeModules: string[] = [];
  private activeSubmodules: string[] = [];

  constructor(private API_URL: Configuration,private http: HttpClient) { }


  fetchRoleList(ids:any): void {
    // const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.ROLE_MAPING}`;
    const url = `${environment.New_BASE_DOMAIN}auth-service/role/getModulesByRoleId?roleIds=${ids}&platform=wms`;

    this.http.get<any>(url).subscribe((res: any) => {
        if (res?.responseObject?.modules) {
          const modules = res.responseObject.modules;
          modules.forEach((element: any) => {
            if (
              element.moduleName === 'Products' ||
              element.moduleName === 'Inbound' ||
              element.moduleName === 'Outbound' ||
              element.moduleName === 'Inventory' ||
              element.moduleName === 'Warehouse' ||
              element.moduleName === 'Account Configurations' ||
              element.moduleName === 'Access Management' ||
              element.moduleName === 'Utility'
              ) {
              this.activeModules.push(element.moduleName);
            }
            element.subModules.forEach((item: any) => {
              if (
                item.subModuleName === "Manage Products" ||
                item.subModuleName === "Manage Attributes" ||
                item.subModuleName === "Channel Configuration" ||
                item.subModuleName === "Manage PO" ||
                item.subModuleName === "Manage ASN" ||
                item.subModuleName === "GRN Details" ||
                item.subModuleName === "Putaway" ||
                item.subModuleName === "Order Management" ||
                item.subModuleName === "Picklist Management" ||
                item.subModuleName === "Sort & Pack" ||
                item.subModuleName === "Manifest" ||
                item.subModuleName === "Manage Inventory" ||
                item.subModuleName === "Inventory summary" ||
                item.subModuleName === "Manage Warehouse" ||
                item.subModuleName === "Manage Layout" ||
                item.subModuleName === "Manage Containers" ||
                item.subModuleName === "Manage Bin" ||
                item.subModuleName === "Active Pigeonhole" ||
                item.subModuleName === "Manage Vendors" ||
                item.subModuleName === "Manage Customers" ||
                item.subModuleName === "Manage Webhooks" ||
                item.subModuleName === "Channel" ||
                item.subModuleName === "User Management" ||
                item.subModuleName === "Role Management" ||
                item.subModuleName === "Common Upload" ||
                item.subModuleName === 'Configure Carriers'
              ) {
                this.activeSubmodules.push(item.subModuleName);
              }
              if(item.isDefault === 1){
                console.log(item)
              }
            });
          });
        }
      },
      (error) => {
        console.error('Failed to fetch role list', error);
      }
    );
  }

  isActiveModule(moduleName: string): boolean {
    // console.log(moduleName)
    return this.activeModules.includes(moduleName);
  }

  isActiveSubmodule(submoduleName: string): boolean {
    // console.log('Orders Report',submoduleName)
    return this.activeSubmodules.includes(submoduleName);
  }
}
