import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent {
  @Input() display: any;
  @Input() message: string = 'Are you sure you want to update?';
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  changePasswordform = new FormGroup({
    password: new FormControl('',[Validators.required,Validators.minLength(3)]),
    confirmPassword: new FormControl('',[Validators.required,Validators.minLength(3)]),
  });
  submitted=false;
  password:any;
  userName:any;
  userRole:any;
  isPasswordVisible: boolean = false;
  eyeShow: boolean = true
  constructor(private messageService: MessageService,private AuthService: AuthService) {}

  ngOnInit(){
    console.log('chnage pass')
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userName = '';
    if(changes){
      const userDetails = JSON.parse(localStorage.getItem('user_details') || '[]');
      this.userRole = userDetails.role
      if(this.userRole === 'ROLE_USER' || this.userRole === 'ROLE_STAFF'){
        this.userName = userDetails.userName
      }
      else{
        this.userName = this.display ? this.display.userDetail.userName : ''
      }
    } 
  }
  
  confirmAction(choice: boolean) {
    if(choice){
      if(this.changePasswordform.controls.password.value === this.changePasswordform.controls.confirmPassword.value){
        this.updatePassword()
        this.confirm.emit(choice);
        // this.display = false;
      }
      else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Password not matched',
        });
      }
    }
    else{
      this.changePasswordform.reset()
      this.confirm.emit(choice);
      this.display = false;
    }
  }

  changeVisible(event:any){
    this.changePasswordform.reset()
    this.confirm.emit(event);
    this.display = false;
  }
  get form(): { [key: string]: AbstractControl } {
    return this.changePasswordform.controls;
  }
  updatePassword(){
    const dataToSend = {
      user: this.userName,
      password: this.changePasswordform.controls.password.value
    }
    this.AuthService.uploadPassword(dataToSend).subscribe((res)=>{
      this.display = false;
      this.changePasswordform.reset()
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Password updated successfully',
      });
      // this.changeVisible(true)
    },(error)=>{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.error.responseMessage ?error.error.responseMessage : 'Something went wrong',
      });
    })
  }
  whiteSpaceValidator(event:any){
    if (event.keyCode === 32) { // Check if the pressed key is the spacebar (key code 32)
        event.preventDefault(); // Prevent the default action (inserting a space)
    }
  }
  eyeShowClick() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}

