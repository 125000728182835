import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageCroppedEvent , base64ToFile } from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { accountConfigurationService } from 'src/app/module/account-configuration/account-configuration-service';
import { CONSTANT_VARIABLE } from '../../constant/constant';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-change-color-modal',
  templateUrl: './change-color-modal.component.html',
  styleUrls: ['./change-color-modal.component.css'],
  providers: [MessageService]
})
export class ChangeColorModalComponent implements OnInit {
  selectedThemeColor = '#000000';
  selectedTextColor = '#000000';
  selectedBgColor = '#f6f4f4';
  selectedSidebarBgColor = '#fff'
  selectedMainTextColor = '#313131'
  bgType = 'gradiant'
  sidebarType = 'left'
  darkTheme: any;
  imageChangedEvent: Event | null = null;
  croppedImage: any;
  cropImageModal = false
  imagename:any
  image:any
  imageChangedEventSmall: Event | null = null;
  croppedImageSmall: any;
  cropImageModalSmall = false
  imagenameSmall:any
  imageSmall:any
  accountId:any;
  accountThemeId:any;
  accountThemeData:any;
  themeConstant = CONSTANT_VARIABLE
  constructor(public ref: DynamicDialogRef,public data: DynamicDialogConfig,private messageService: MessageService, private sanitizer: DomSanitizer,private accountService: accountConfigurationService) {
    console.log(data) 
    this.accountId = data?.data?.data?.id
    this.accountThemeId = data?.data?.data?.accountThemeInfo?.id
    this.accountThemeData = data?.data?.data?.accountThemeInfo
  }

  ngOnInit() {
    if(this.accountThemeId){
      this.selectedThemeColor = this.accountThemeData?.backgroundPrimaryColor 
      this.selectedBgColor = this.accountThemeData?.backgroundMainColor 
      this.selectedSidebarBgColor = this.accountThemeData?.backgroundSidebarColor
      this.selectedMainTextColor = this.accountThemeData?.mainTextColor 
      this.bgType = this.accountThemeData?.bgType 
      this.sidebarType = this.accountThemeData?.sidebarType
    }
    else{
      this.selectedThemeColor = this.themeConstant.backgroundPrimaryColor
      this.selectedBgColor = this.themeConstant.backgroundMainColor
      this.selectedSidebarBgColor = this.themeConstant.backgroundSidebarColor
      this.selectedMainTextColor = this.themeConstant.mainTextColor
      this.bgType = this.themeConstant.bgType
      this.sidebarType = this.themeConstant.sidebarType
    }
    this.croppedImage = localStorage.getItem('orgLogo')
    this.croppedImageSmall = localStorage.getItem('orgSmallLogo')
  }
  submit(){
    if(this.accountId){
      this.submitTheme()
    }
    localStorage.setItem('backgroundPrimaryColor', this.selectedThemeColor);
    localStorage.setItem('backgroundPrimaryHoverColor', (this.selectedThemeColor + '14'));
    document.documentElement.style.setProperty('--background-primary-color', this.selectedThemeColor);
    document.documentElement.style.setProperty('--background-primary-hover-color', (this.selectedThemeColor + '14'));
    
    const croppedImage = localStorage.getItem('orgLogo') 
    const croppedImageSmall = localStorage.getItem('orgSmallLogo') 

    localStorage.setItem('backgroundSidebarColor', this.selectedSidebarBgColor);
    document.documentElement.style.setProperty('--sidebar-background-color', this.selectedSidebarBgColor);

    localStorage.setItem('mainTextColor', this.selectedMainTextColor);
    document.documentElement.style.setProperty('--main-text-color', this.selectedMainTextColor);
    
    localStorage.setItem('bgType', this.bgType);
    const sidebarType = localStorage.getItem('sidebarType')
    
    localStorage.setItem('backgroundMainColor', this.selectedBgColor);
    if(this.bgType === 'gradiant'){
      document.body.style.backgroundColor = ''
      document.body.style.setProperty('--main-background-color', this.selectedBgColor);
    }
    else{
      document.body.style.setProperty('--main-background-color', this.selectedBgColor);
      this.darkTheme = localStorage.getItem('darkTheme')
      if(this.darkTheme === 'true'){
        document.body.style.backgroundColor = ''
      }
      else{
        document.body.style.backgroundColor = this.selectedBgColor
      }
    }
    if(this.accountId){
      localStorage.setItem('orgLogo', this.croppedImage)
      localStorage.setItem('orgSmallLogo', this.croppedImageSmall)
      // if(croppedImage != this.croppedImage){
      //   setTimeout(() => {
      //     location.reload();
      //   }, 1000)
      // }
      this.ref.close(true)
    }
    else{
      if(sidebarType !== this.sidebarType || croppedImage != this.croppedImage || croppedImageSmall != this.croppedImageSmall){
        // this.messageService.add({ severity: 'info', summary: 'Info', life: 5000, detail: 'Your have changes the sidebar view for changing view reloading the page' });
        localStorage.setItem('sidebarType', this.sidebarType);
        localStorage.setItem('orgLogo', this.croppedImage)
        localStorage.setItem('orgSmallLogo', this.croppedImageSmall)
        setTimeout(() => {
          location.reload();
          this.ref.close(true)
        }, 1000);
      }
      else{
        this.ref.close(true)
      }
    }
  }
  reset(){
    document.documentElement.style.setProperty('--background-primary-color', '#973CB6');
    document.documentElement.style.setProperty('--background-primary-hover-color', '#973CB614');
    document.body.style.setProperty('--main-background-color', '#f6f4f4');
    document.documentElement.style.setProperty('--sidebar-background-color', '#ffffff');
    document.documentElement.style.setProperty('--main-text-color', '#313131');
    this.bgType = 'gradiant'
    this.sidebarType = 'left'
    this.selectedThemeColor = '#973CB6'
    this.selectedBgColor = '#f6f4f4'
    this.selectedSidebarBgColor = '#ffffff'
    this.selectedMainTextColor = '#313131'
    this.croppedImage = "string"
    localStorage.setItem('backgroundPrimaryColor', this.selectedThemeColor);
    localStorage.setItem('backgroundPrimaryHoverColor', (this.selectedThemeColor + '14'));
    localStorage.setItem('backgroundMainColor', this.selectedBgColor);
    localStorage.setItem('backgroundSidebarColor', this.selectedSidebarBgColor);
    localStorage.setItem('mainTextColor', this.selectedMainTextColor);
    localStorage.setItem('bgType', this.bgType);
    localStorage.setItem('sidebarType', this.sidebarType);
    if(this.accountId){
      this.submitTheme()
      setTimeout(() => {
        this.ref.close(true)
      }, 1000);
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imagename = event.target.files[0];
    this.cropImageModal = true
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const imageBlob = this.dataURItoBlob(this.croppedImage);
    this.image = new File([imageBlob], this.imagename.name, { type: imageBlob.type });
    
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
    // event.blob can be used to upload the cropped image
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  dataURItoBlob(dataURI:any) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob(
      [new Uint8Array(array)],
      {
        type: 'image/png',
      },
    );
  }

  saveCroppedImg(){
    this.cropImageModal = false
    this.uploadFile(this.image)
  }

  base64ToFile(data:any, filename:any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  submitTheme(){
    const dataToSend = {
      "id": this.accountThemeId ? this.accountThemeId : null,
      "backgroundMainColor": this.selectedBgColor,
      "backgroundPrimaryColor": this.selectedThemeColor,
      "backgroundPrimaryHoverColor": this.selectedSidebarBgColor + '14',
      "backgroundSidebarColor": this.selectedSidebarBgColor,
      "bgType": this.bgType,
      "mainTextColor": this.selectedMainTextColor,
      "sidebarType": this.sidebarType,
      "orgLogo": this.croppedImage ? this.croppedImage : null,
      "orgSmallLogo": this.croppedImageSmall ? this.croppedImageSmall : null,
      "accountId": this.accountId ? this.accountId: null
    }
    this.accountService.addOrUpdateTheme(dataToSend).subscribe((res:any)=>{

    })
  }
  uploadFile(file:any){
    this.accountService.upload(file).subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          // Update progress percentage
        } else if (event.type === HttpEventType.Response) {
          // Handle the final response
          if (event.body?.responseStatus) {
            this.croppedImage = event.body.responseObject;
          } else {
            console.error('Upload Failed:', event.body?.responseMessage);
          }
        }
      },
      error: (err) => {
        console.error('Error during file upload:', err);
      }
    })
  }

  fileChangeEventSmall(event: any): void {
    this.imageChangedEventSmall = event;
    this.imagenameSmall = event.target.files[0];
    this.cropImageModalSmall = true
  }
  imageCroppedSmall(event: ImageCroppedEvent) {
    this.croppedImageSmall = event.base64;
    const imageBlob = this.dataURItoBlob(this.croppedImageSmall);
    this.imageSmall = new File([imageBlob], this.imagenameSmall.name, { type: imageBlob.type });
    
    // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
    // event.blob can be used to upload the cropped image
  }
  imageLoadedSmall() {
      // show cropper
  }
  cropperReadySmall() {
      // cropper ready
  }
  loadImageFailedSmall() {
      // show message
  }
  saveCroppedImgSmall(){
    this.cropImageModalSmall = false
    this.uploadFileSmall(this.imageSmall)
  }
  uploadFileSmall(file:any){
    this.accountService.upload(file).subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          // Update progress percentage
        } else if (event.type === HttpEventType.Response) {
          // Handle the final response
          if (event.body?.responseStatus) {
            this.croppedImageSmall = event.body.responseObject;
          } else {
            console.error('Upload Failed:', event.body?.responseMessage);
          }
        }
      },
      error: (err) => {
        console.error('Error during file upload:', err);
      }
    })
  }
}
