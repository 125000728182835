import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
// import { ImportOrderComponent } from './orders/container/import-order/import-order.component';
import { auth2Guard } from './_guard/auth2.guard';
import { authGuard } from './_guard/auth.guard';
import { PagenotfoundComponent } from './shared/component/pagenotfound/pagenotfound.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: '',
    // canLoad: [auth2Guard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [authGuard],
    children: [
      // {
      //   path: 'import-order',
      //   component: ImportOrderComponent,
      //   // canActivate: [AuthGuard],
      //   data: { title: 'Opollo WMS' }
      // },
      {
        path: 'warehouse-services',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/warehouse/warehouse.module').then(m => m.WarehouseModule)
      },
      {
        path: 'product-services',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/product/product.module').then(m => m.ProductModule)
      },
      {
        path: 'receiving-services',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/receiving/receiving.module').then(m => m.ReceivingModule)
      },
      {
        path: 'inventory-services',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
        path: 'access-management-services',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/access-management/access-management.module').then(m => m.AccessManagementModule)
      },
      {
        path: 'order-services',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'dashboard',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'setting',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'utility',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/utility/utility.module').then(m => m.UtilityModule)
      },
      {
        path: 'channel',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/channel/channel.module').then(m => m.channelModule)
      },
      {
        path: 'account-configuration',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./module/account-configuration/account-configuration.module').then(m => m.AccountConfigurationModule)
      },
      {
        path: 'rule-services',
        loadChildren: () => import('./module/rule-engine/rule-engine.module').then(m => m.RuleEngineModule)
      },
    ]
  },
  { path: 'pageNotFound', component: PagenotfoundComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
