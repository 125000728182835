import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { Observable, finalize, tap } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { MessageService } from 'primeng/api';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private messageService: MessageService,
    private sharedService: SharedService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.sharedService.updateLoader(true);

    const authToken = this.auth.getAuthToken() || localStorage.getItem('access_token');

    let headers = request.headers
      .set('DEVICE-TYPE', 'Web')
      .set('VER', '1');
    
    if (authToken) {
      headers = headers.set('Authorization', `Bearer ${authToken}`);
    }

    // Clone request to include `withCredentials`
    const requestToHandle = request.clone({
      headers,
      withCredentials: true, // Required for cookies
    });

    return next.handle(requestToHandle).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Handle response if needed
          }
        },
        (error: any) => {
          this.sharedService.updateLoader(false);

          if (error.status === 401) {
            this.auth.logOut();
          } 
          else if (error.status === 404) {
            // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error ? error.error.responseMessage : 'Something went wrong' });
          }else {
            console.error('Error:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.error ? error.error.responseMessage : 'Something went wrong',
            });
          }
        }
      ),
      finalize(() => {
        this.sharedService.updateLoader(false);
      })
    );
  }
}

export let tokenProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
